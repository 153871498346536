import React, { useEffect, useRef } from 'react';
import lottie from 'lottie-web';

import * as Util from "../../utils/util";
import MainApp from "./MainApp";

const AppLoading = ({ message }) => {
	const container = useRef(null);

	useEffect(() => {
		Util.setWebviewContainerHeight(Util.HEIGHT_DEFAULT);
		// const spinnerPath = `/assets/images/spinner${theme === 'dark' ? '-dark' : ''}.json`;
		const spinnerPath = `/assets/images/spinner.json`;

		let animation;
		setTimeout(_ => {
			animation = lottie.loadAnimation({
				container: container.current,
				renderer: 'svg',
				loop: true,
				autoplay: true,
				path: spinnerPath,
			});
		}, 450);

		// Cleanup the animation instance when the component is unmounted
		return () => {
			if(animation) animation.destroy();
		};
	}, []);

	return <MainApp>
		<div className="loading-wrapper">
			<div className="lottie-loader" ref={container}/>
			<span>{message}</span>
		</div>
	</MainApp>;
};

export default AppLoading;
