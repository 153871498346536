import React, { useRef, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';

import { auth } from '../firebase';
import * as Util from "../utils/util";
import { BASE_URL, handleBlur, handleFocus } from "../utils/util";
import InputField from "./components/InputField";
import { countries } from "../utils/countries";
import axios from "axios";
import AppLoading from "./components/AppLoading";
import MainApp from "./components/MainApp";

let recaptchaVerifier = null;
const PhoneNumberLogin = () => {
	const [loading, setLoading] = useState(false);
	const [pageLoading, setPageLoading] = useState(true);
	const [buttonDisabled, setButtonDisabled] = useState(true);
	const [verifyModeEnabled, setVerifyModeEnabled] = useState(false);
	const [dialCode, setDialCode] = useState("+234");
	const [number, setNumber] = useState("");
	const [error, setError] = useState("");

	const [counter, setCounter] = useState(0);
	const [result, setResult] = useState(null);
	const [otp, setOTP] = useState("");

	const navigate = useNavigate();
	const inputRef = useRef();

	useEffect(() => {
		axios.get('https://ipapi.co/json').then(response => {
			const locationData = response.data;
			if(!locationData || !locationData['country_calling_code']) return;
			setDialCode(locationData['country_calling_code']);
			// let location = countries.filter(value => (value['iso'] || '').trim().toUpperCase() === (locationData['iso'] || '').trim().toUpperCase());
			// if(location.length < 1) return; location = location[0];
			// setDialCode(location['dialCode']);
			setPageLoading(false);
		}).catch(err => {
			console.error(err);
			setDialCode(countries[0]['dialCode']);
		});
	}, []);

	useEffect(() => {
		if(!recaptchaVerifier) {
			setVerifyModeEnabled(false);
			return;
		}

		sendOneTimePasscode().then(() => {});
	}, [ verifyModeEnabled ]);

	useEffect(() => {
		if(counter <= 0) return;
		setTimeout(() => setCounter(counter - 1), 1000);
	}, [counter]);

	const clearRecaptchaVerifier = () => {
		if(recaptchaVerifier) {
			recaptchaVerifier.clear();
			recaptchaVerifier = null;
		}
	};

	const validatePhoneNumber = async (event) => {
		event.preventDefault();
		if(buttonDisabled || loading) return;
		if(number.trim().length < 7) {
			setError("Enter a valid phone number");
			return;
		}

		setError("");
		setButtonDisabled(true);
		let phoneNumber = number.trim().replaceAll("+", "");
		const preferredDialCode = dialCode.replaceAll("+", "");
		if(!phoneNumber.startsWith(preferredDialCode)) phoneNumber = preferredDialCode+phoneNumber;
		if(phoneNumber.startsWith("2340")) phoneNumber = phoneNumber.replace("2340", "234");
		if(phoneNumber.startsWith("2540")) phoneNumber = phoneNumber.replace("2540", "254");

		recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {
			// 'size': 'normal',
			'callback': (_) => {
				// reCAPTCHA solved, allow signInWithPhoneNumber.
				setNumber(phoneNumber);
				setVerifyModeEnabled(true);
				setButtonDisabled(false);
				setLoading(false);
			},
			'expired-callback': () => {
				// Response expired. Ask user to solve reCAPTCHA again.
				setError("Verification expired, try again");
				setButtonDisabled(false);
				setLoading(false);
			}
		}, auth);

		try {
			await recaptchaVerifier.render();
		} catch (e) {
			setError(e.message);
			console.error("Recaptcha Verification Err:", e);
			clearRecaptchaVerifier();
		}
	};

	const sendOneTimePasscode = async () => {
		if(counter > 0) return;
		try {
			const confirmationResult = await signInWithPhoneNumber(auth, `+${number}`, recaptchaVerifier); // GETS STUCK HERE SOMETIMES
			setResult(confirmationResult);
			setCounter(900); // INCREASING FROM 2 TO 15 MINUTES
		} catch(err) {
			console.error(err);
			clearRecaptchaVerifier();
			if(err.message.includes("invalid-phone-number")) {
				setError("In valid phone number, please confirm the phone number and country code");
			} else setError(err.message);
			setVerifyModeEnabled(false);
			if(loading) setLoading(false);
			if(buttonDisabled) setButtonDisabled(false);
		}
	};

	const resendOneTimePasscode = async (event) => {
		event.preventDefault();
		sendOneTimePasscode().then(() => {});
	};

	const validateOTP = async (event) => {
		event.preventDefault();
		if(!result || loading || buttonDisabled) return;

		try {
			setError("");
			await result.confirm(otp);
			const token = await auth.currentUser.getIdToken();
			setButtonDisabled(false);

			setLoading(true);
			const response = await axios.post(`${BASE_URL}/app/auth`, { phone: `+${number}`, token });
			if(response.data.authToken) {
				// localStorage.setItem("sess:id", response.data.authToken);
				navigate("/transactions", { replace: true, state: { authToken: response.data.authToken } });
			} else {
				setError("Unable to login at this time, please try again.");
				setLoading(false);
			}
		} catch (err) {
			console.error(err);
			setError(err.message);
		} finally {
			setLoading(false);
			setButtonDisabled(false);
		}
	};

	const numberChangeHandler = (event) => {
		if(isNaN(event.target.value.trim())) return;
		setButtonDisabled(event.target.value.trim().length < 7);
		setNumber(event.target.value.trim());
		setError('');

		if(recaptchaVerifier) {
			clearRecaptchaVerifier();
			if(buttonDisabled) setButtonDisabled(false);
		}
	};

	const otpChangeHandler = (event) => {
		setButtonDisabled(event.target.value.trim().length < 6);
		setOTP(event.target.value.trim());
		setError('');
	};

	const optionChangeHandler = (event) => {
		setDialCode(event.target.value.trim());
	};

	const navigateBack = (event) => {
		event.preventDefault();
		clearRecaptchaVerifier();
		setVerifyModeEnabled(false);
	};

	if(pageLoading) return <AppLoading message={"Loading..."} />;

	const style = {};
	if(Util.device === 'ios') {
		style.top = "64px";
	}

	return (
		<MainApp>
			{verifyModeEnabled && <div className="back-navigator-button" style={style} onClick={navigateBack}>
				<img src={'/assets/images/arrow-back.png'} alt={'error'}/>
			</div>}
			<div className="title" style={{ marginTop: "100px" }}>{ verifyModeEnabled ? "Verify your phone number" : "Welcome to Vendy"}</div>
			<div className="subtitle">{ verifyModeEnabled ? "Enter the OTP sent to your phone number, if you did not get one you can request another" : "You’ll receive an sms with a one-time password to verify your account"}</div>
			<form onSubmit={ verifyModeEnabled ? validateOTP : validatePhoneNumber} style={{ marginTop: verifyModeEnabled ? "46px" : "40px" }}>
				{verifyModeEnabled ? <InputField
					id="otp"
					name="otp"
					value={otp}
					type="password"
					autoFocus={true}
					inputMode="numeric"
					onChange={otpChangeHandler}
					onFocus={handleFocus}
					onBlur={handleBlur}
					label="Verification code"
					error={error}
				/> : <div className="form-field-group">
					<div className="upi-widget-text-field">
						<label htmlFor="dial-code" className={"upi-widget-text-field-focused-label"}>Country/Region</label>
						<select id="dial-code" onChange={optionChangeHandler} defaultValue={dialCode}>{ countries.map(function(country){
							return <option key={country['dialCode']+country['name']} value={country['dialCode']}>{country['name']} ({country['dialCode']})</option>;
						}) }</select>
					</div>
					<InputField
						id="number"
						type="text"
						name="number"
						value={number}
						autoFocus={true}
						inputMode="numeric"
						inputRef={inputRef}
						onChange={numberChangeHandler}
						onFocus={handleFocus}
						onBlur={handleBlur}
						label="Phone number"
					/>
				</div>}
				<div id="recaptcha-container" style={{ display: verifyModeEnabled ? "none" : "block" }}/>
				{error && <div className="upi-widget-text-error">
					<img src={'/assets/images/error-small.png'} alt={'error'}/> {error}
				</div>}
				{verifyModeEnabled && <div className="retry-counter" style={{ marginTop: error ? "8px" : "-2px" }}>
					{ counter === 0 ? <a onClick={resendOneTimePasscode}>Resend code</a> :
						`Having trouble? Request new OTP in ${Math.floor(counter/60) > 9 ? '' : '0'}${Math.floor(counter/60)}:${(counter%60) > 9 ? '' : '0'}${counter%60}`
					}
				</div>}
				<button className={`rounded-button bottom${buttonDisabled || loading ? " disabled" : ""}`} type="submit">{verifyModeEnabled ? "Continue" : "Send code"}</button>
			</form>
		</MainApp>
	);
};

export default PhoneNumberLogin;
