import React, {useEffect, useRef, useState} from 'react';
import lottie from 'lottie-web';

import { theme } from '../../utils/theme';

import * as Util from "../../utils/util";

const Loading = ({ message, animate }) => {
	const container = useRef(null);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		Util.setWebviewContainerHeight(Util.HEIGHT_DEFAULT);
		const spinnerPath = `/assets/images/spinner${theme === 'dark' ? '-dark' : ''}.json`;

		let animation;
		setTimeout(_ => {
			animation = lottie.loadAnimation({
				container: container.current,
				renderer: 'svg',
				loop: true,
				autoplay: true,
				path: spinnerPath,
			});
			setLoading(false);
		}, 450);

		// Cleanup the animation instance when the component is unmounted
		return () => {
			if(animation) animation.destroy();
		};
	}, []);

	return (
		<div className={`upi-widget-container ${animate ? 'animate' : ''}`}>
			<div className="loading-wrapper">
				<div className="lottie-loader" ref={container}/>
				{!loading && (<span>{message}</span>)}
			</div>
		</div>
	);
};

export default Loading;
