
export const formatCardNumber = (value) => {
	return value
		.replace(/\D/g, '')
		.replace(/(\d{4})/g, '$1 ')
		.trim();
};

export const formatExpiry = (value) => {
	return value
		.replace(/\D/g, '')
		.replace(/(\d{2})/, '$1/')
		.substring(0, 5);
};

export const getCardType = (cardNumber) => {
	let cardType;
	const first2digits = (cardNumber.length <= 2) ? parseInt(cardNumber) : parseInt(cardNumber.substring(0, 2));
	const first4digits = (cardNumber.length <= 4) ? parseInt(cardNumber) : parseInt(cardNumber.substring(0, 4));
	const first6digits = (cardNumber.length <= 6) ? parseInt(cardNumber) : parseInt(cardNumber.substring(0, 6));
	if(cardNumber.length >= 6 && first6digits != null && first6digits >= 506099 && first6digits <= 506198){
		cardType = 'verve';
	} else if(cardNumber.length >= 6 && first6digits != null && first6digits >= 650002 && first6digits <= 650027){
		cardType = 'verve';
	} else if(cardNumber.length >= 4 && first4digits != null && first4digits >= 2221 && first4digits <= 2720){
		cardType = 'mastercard';
	} else if(first2digits != null && first2digits >= 50 && first2digits <= 55){
		cardType = 'mastercard';
	} else if(cardNumber.startsWith('34') || cardNumber.startsWith('37')){
		cardType = 'americanexpress';
	} else if(cardNumber.startsWith('4') && cardNumber.length >= 2){
		cardType = 'visa';
	} else { cardType = "default-card"; }
	return cardType;
};
