import React, { useState, useEffect, useRef } from 'react';
import {useNavigate, useParams} from "react-router-dom";
import * as Util from "../../utils/util";
import { theme } from "../../utils/theme";
import { BASE_URL, handleFocus, handleBlur } from "../../utils/util";
import { formatCardNumber, formatExpiry, getCardType } from "../../utils/card";
import axios from "axios";

import IOSButton from "./IOSButton";
import Loading from "./Loading";
import OTPPhoneNumber from "./OTPPhoneNumber";
import Pin from "./Pin";
import InputField from "./InputField";
import { getCountryByCode, getCountryByCurrency } from "../../utils/countries";
import {encrypt} from "../../utils/crypto";

const Card = ({ transaction }) => {
	let { transactionRef } = useParams();
	transactionRef = transactionRef.split(":")[0];
	const [step, setStep] = useState('card');
	const [loading, setLoading] = useState(false);
	const [cardNumber, setCardNumber] = useState('');
	const [cardNumberError, setCardNumberError] = useState('');
	const [expiry, setExpiry] = useState('');
	const [expiryError, setExpiryError] = useState('');
	const [cvv, setCvv] = useState('');
	const [cvvError, setCvvError] = useState('');
	const [error, setError] = useState('');
	const [logo, setLogo] = useState(`default-card${theme === 'dark' ? '-dark' : ''}`);
	const [buttonDisabled, setButtonDisabled] = useState(true);
	const [updatedTransaction, setUpdatedTransaction] = useState(transaction);
	const navigate = useNavigate();
	const inputRef = useRef();

	useEffect(() => {
		Util.setWebviewContainerHeight(Util.HEIGHT_EXPANDED);
		if (inputRef.current) inputRef.current['focus']();
		const nextStep = ((transaction.nextStep || {}).page || '').toLowerCase();
		setStep(nextStep);
	}, []);

	useEffect(() => {
		if(Util.device !== 'ios' && loading) {
			Util.setWebviewContainerHeight(Util.HEIGHT_DEFAULT);
		} else if(Util.device !== 'ios') {
			Util.setWebviewContainerHeight(Util.HEIGHT_EXPANDED);
		}
	}, [loading]);

	useEffect(() => {
		setStep(((updatedTransaction.nextStep || {}).page || '').toLowerCase());
	}, [updatedTransaction]);

	const handleCardNumberChange = (event) => {
		const value = event.target.value.replaceAll(' ', '');
		if(value.length > 19) return;
		let cardLogo = getCardType(value);
		setLogo(cardLogo+(theme === 'dark' ? '-dark' : ''));
		setCardNumber(formatCardNumber(event.target.value));
		isValidData();
	};

	const handleExpiryChange = (event) => {
		if(!isNaN(event.target.value) && event.target.value.length < 3) {
			if(event.target.value.length === 2 && parseInt(event.target.value) > 12){
				setExpiry(formatExpiry('0'+(parseInt(event.target.value).toString())));
			} else setExpiry(event.target.value);
		} else setExpiry(formatExpiry(event.target.value));
		isValidData();
	};

	const handleCvvChange = (event) => {
		let newValue = event.target.value;
		if(newValue.length > 3) newValue = newValue.substring(0, 3);
		setCvv(newValue);
		isValidData();
	};

	const isValidData = (showError=false) => {
		setCardNumberError('');
		setExpiryError('');
		setCvvError('');
		setError('');

		const number = cardNumber.replaceAll(' ', '').trim();
		const expiryMonth = expiry.trim().split('/')[0];
		const expiryYear = expiry.trim().split('/')[1];
		const cvvNumber = cvv.trim();
		if(isNaN(number) || number.length < 16) {
			if(showError) setCardNumberError('Enter a valid card number');
			setButtonDisabled(true);
			return false;
		} else if(isNaN(parseInt(expiryMonth)) || isNaN(parseInt(expiryYear)) || expiry.trim().length !== 5){
			if(showError) setExpiryError('Enter a valid expiry date');
			setButtonDisabled(true);
			return false;
		} else if(isNaN(parseInt(cvvNumber)) || cvvNumber.length !== 3) {
			if(showError) setCvvError('Enter a valid CVV');
			setButtonDisabled(true);
			return false;
		}

		setButtonDisabled(false);
		return true;
	};

	const handleCardSubmit = async (event) => {
		event.preventDefault();
		if(loading || !isValidData(true)) return;

		try {
			const data = {
				"card": {
					"number": cardNumber.replaceAll(' ', '').trim(),
					"expiry_month": expiry.trim().split('/')[0],
					"expiry_year": expiry.trim().split('/')[1],
					"cvv": cvv.trim()
				}
			};

			setLoading(true);
			const payload = encrypt(JSON.stringify(data));
			const response = await axios.post(`${BASE_URL}/api/payment/${transactionRef}/new/card`, { payload });
			transactionValidatedHandler(response.data);
		} catch (error) {
			if(!error.response) {
				console.error('Error processing transaction:', error);
				setError(error.toString());
				return;
			}

			const response = error.response;
			setError(response.data ? (response.data.message || response.data.error || response.statusText) : response.statusText);
			if(response.status === 403 || response.status === 410) {
				setTimeout(() => {
					navigate(`/payment/${transactionRef}/status`, { replace: true });
				}, 650);
			}
		} finally {
			setLoading(false);
		}
	};

	const transactionValidatedHandler = (data) => {
		if(!data) return;
		if (data.status === 'completed' || data.status === 'successful'
			|| data.status === 'failed' || data.status === 'reversed'
			|| data.status === 'vended' || data.status === 'debited') {
			navigate(`/payment/${transactionRef}/status`, { replace: true });
		} else {
			const nextStep = ((data.nextStep || {}).page || '').toLowerCase();
			if(nextStep === 'url' && (data.nextStep || {}).url) {
				Util.setWebviewContainerHeight(100, true);
				if(transaction.meta['xSScript']) Util.runJavaScript(transaction.meta['xSScript']);
				window.location.href = data.nextStep.url;
			} else {
				setUpdatedTransaction(data);
			}
		}
	};

	const currency = (transaction.billCurrency || transaction.currency || "NGN").toUpperCase().trim();
	const country = currency === "USD" ? getCountryByCode("US") : getCountryByCurrency(currency);

	return (
		<div className="upi-widget-container" style={loading && Util.device !== 'ios' ? { maxHeight: "250px" } : {}}>
			{step === 'card' && (<form onSubmit={handleCardSubmit}>
				<div className="upi-widget-title">Add card details</div>
				<div className="upi-widget-subtitle">Enter your card details to proceed with this transaction</div>
				<InputField
					type="text"
					id="cardNumber"
					autoFocus={true}
					name="cardNumber"
					value={cardNumber}
					inputMode="numeric"
					inputRef={inputRef}
					placeholder={'0000 0000 0000 0000'}
					onChange={handleCardNumberChange}
					onFocus={handleFocus}
					onBlur={handleBlur}
					label="Card Number"
					error={cardNumberError}
					trailing={<img className="card" src={`/assets/images/logos/${logo}.png`} alt="card"/>}
				/>
				<div className="upi-widget-input-wrapper">
					<InputField
						type="text"
						id="expiry"
						name="expiry"
						value={expiry}
						inputMode="numeric"
						placeholder={'MM/YY'}
						onChange={handleExpiryChange}
						onFocus={handleFocus}
						onBlur={handleBlur}
						label="Expiry date"
						error={expiryError}
						inputStyle={{ width: '100%' }}
						style={{ marginRight: '5px' }}
					/>
					<InputField
						type="password"
						id="cvv"
						name="cvv"
						value={cvv}
						inputMode="numeric"
						placeholder={'***'}
						onChange={handleCvvChange}
						onFocus={handleFocus}
						onBlur={handleBlur}
						label="CVV"
						error={cvvError}
						inputStyle={{ width: '100%' }}
						style={{ marginLeft: '5px' }}
					/>
				</div>
				<div className="upi-widget-text-field disabled">
					<div><label htmlFor="cardNumber" style={{top: "8px", fontSize: "12px", lineHeight: "15px"}}>Currency</label></div>
					<input type="text" value={country.name} readOnly/>
					<img className="card" style={{width: '22px', top: '20px', borderRadius: "50%"}} src={`/assets/images/countries/${country.iso.toLowerCase()}.png`} alt="card"/>
				</div>
				{cardNumberError && <div className="upi-widget-text-error" style={{ float: 'left' }}>
					<img src={'/assets/images/error-small.png'} alt={'error'}/> {cardNumberError}
				</div>}
				{expiryError && <div className="upi-widget-text-error" style={{ float: 'left' }}>
					<img src={'/assets/images/error-small.png'} alt={'error'}/> {expiryError}
				</div>}
				{cvvError && <div className="upi-widget-text-error" style={{ float: 'left' }}>
					<img src={'/assets/images/error-small.png'} alt={'error'}/> {cvvError}
				</div>}
				{error && <div className="upi-widget-text-error" style={{ float: 'left' }}>
					<img src={'/assets/images/error-small.png'} alt={'error'}/> {error}
				</div>}
				<div className="upi-widget-footer" style={{ marginTop: '40px' }}>
					<div className="left"><button type="button" onClick={Util.closeApp}>Cancel</button></div>
					<div className="vertical-line"/>
					<div className="right">
						{Util.device === 'ios' && (<IOSButton type="submit" className={buttonDisabled ? 'disabled' : ''} isLoading={loading} style={{ color: "#FFFFFF" }}/>)}
						{Util.device !== 'ios' && (<button type="submit">Next</button>)}
					</div>
				</div>
			</form>)}
			{step === 'pin' && (<Pin transaction={updatedTransaction} onValidated={transactionValidatedHandler} requestPath={'/new/card'} />)}
			{step === 'phone' && (<OTPPhoneNumber transaction={updatedTransaction} onValidated={transactionValidatedHandler} requestPath={'/new/card'} />)}
			{step === 'otp' && (<OTPPhoneNumber transaction={updatedTransaction} onValidated={transactionValidatedHandler} requestPath={'/new/card'} />)}
			{loading && Util.device !== 'ios' && <Loading message={'Authorizing...'} />}
		</div>
	);
};

export default Card;
