import React, { useEffect, useRef } from 'react';
import lottie from 'lottie-web';

const IOSButton = ({ type, onClick, className, isLoading, style, text }) => {
	const container = useRef(null);

	useEffect(() => {
		const spinnerPath = `/assets/images/spinner-dark.json`;
		const animation = lottie.loadAnimation({
			container: container.current,
			renderer: 'svg',
			loop: true,
			autoplay: true,
			path: spinnerPath,
		});

		// Cleanup the animation instance when the component is unmounted
		return () => {
			animation.destroy();
		};
	}, [isLoading]);

	if(isLoading) {
		return (
			<button type={type} className={className} style={style}>
				<div className="lottie-loader" ref={container}/>
			</button>
		);
	}

	return (<button type={type} className={className} onClick={onClick} style={style}>{text || 'Continue'}</button>);
};

export default IOSButton;
