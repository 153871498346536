import React, {useEffect, useRef} from 'react';
import * as Util from "../../utils/util";
import IOSButton from "./IOSButton";
import { theme } from "../../utils/theme";
import lottie from "lottie-web";

const Status = ({ title, message, onButtonPressed, buttonText='Done', rawStatus='error', verifying=false }) => {
	const container = useRef(null);
	const type = rawStatus === 'completed' || rawStatus === 'successful' ? 'success' : (
		rawStatus === 'pending' || rawStatus === 'delivered' || rawStatus === 'reversing' ||
		rawStatus === 'vended' || rawStatus === 'debited' || rawStatus === 'processing' ||
		rawStatus === 'debited' ? 'info' : 'error'
	);

	useEffect(() => {
		Util.setWebviewContainerHeight(Util.HEIGHT_DEFAULT);
		let animation;
		if(type === 'success') {
			const imagePath = `/assets/images/success${theme === 'dark' ? '-dark' : ''}.json`;
			animation = lottie.loadAnimation({
				container: container.current,
				renderer: 'svg',
				loop: false,
				autoplay: true,
				path: imagePath
			});
		} else if(verifying) {
			const spinnerPath = `/assets/images/spinner${theme === 'dark' ? '-dark' : ''}.json`;
			animation = lottie.loadAnimation({
				container: container.current,
				renderer: 'svg',
				loop: true,
				autoplay: true,
				path: spinnerPath
			});
		}

		// Cleanup the animation instance when the component is unmounted
		return () => {
			if(animation) animation.destroy();
		};
	}, []);

	return (
		<div className="upi-widget-container">
			<div className="loading-wrapper error">
				{type === 'info' && verifying && (<div className="lottie-loader" ref={container}/>)}
				{type === 'info' && !verifying && (<img src={`/assets/images/info${theme === 'dark' ? '-dark' : ''}.png`} alt="info"/>)}
				{type === 'error' && (<img src={'/assets/images/error.png'} alt="error"/>)}
				{type === 'success' && (<div className="lottie-loader success" ref={container}/>)}
				<h1>{title}</h1>
				<p>{message}</p>
				<div className="upi-widget-footer">
					{Util.device !== 'ios' && (<div className="right">
						<button type="button" onClick={onButtonPressed || Util.closeApp}>{buttonText}</button>
					</div>)}
					{Util.device === 'ios' && (<div className="center">
						<IOSButton type="button" onClick={onButtonPressed || Util.closeApp} text={buttonText}/>
					</div>)}
				</div>
			</div>
		</div>
	);
};

export default Status;
