// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
	apiKey: "AIzaSyCO8hjiw8m4-MExFoehimNBd40rCtHhj4I",
	authDomain: "vendy-731cd.firebaseapp.com",
	projectId: "vendy-731cd",
	storageBucket: "vendy-731cd.appspot.com",
	messagingSenderId: "300758823448",
	appId: "1:300758823448:web:f1cee020cd43b4b502096d",
	measurementId: "G-B1L67438CY"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
export const auth = getAuth(app);
export default app;
