import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import axios from "axios";
import * as Util from "../utils/util";
import { BASE_URL } from "../utils/util";
import MainApp from "./components/MainApp";
import { formatCurrency, getFormattedDate } from "../utils/formatters";

const TransactionDetails = () => {
	const location = useLocation();
	const { transactionRef } = useParams();

	const [invoiceStatus, setInvoiceStatus] = useState("pending");
	const [transaction, setTransaction] = useState(location.state ? (location.state.transaction || {}) : {});
	// const [loading, setLoading] = useState(true);
	const [error, setError] = useState("");
	const navigate = useNavigate();

	if(!location.state || !location.state.authToken) {
		navigate('/', { replace: true });
	}

	const { authToken } = location.state;
	const headers = {
		'Content-Type': 'application/json',
		'Authorization': `Bearer ${authToken}`,
	};

	useEffect(() => {
		getInvoiceStatus();
		axios.get(`${BASE_URL}/app/transactions/${transactionRef}`, { headers, withCredentials: false })
			.then(response => setTransaction(response.data))
			.catch(err => {
				console.error(err);
				setError(err.message);
			// }).finally(() => setLoading(false));
			});
	}, []);

	useEffect(() => {
		setInvoiceStatus(getInvoiceStatus());
	}, [ transaction ]);

	const getInvoiceStatus = () => {
		let status = transaction['status'];
		if(transaction["status"] === "delivered" || transaction["status"] === "processing" || transaction["status"] === "debited" || transaction["status"] === "vended" || transaction["status"] === "reversing") {
			status = "pending";
		} else if(transaction["status"] === "successful" || transaction["status"] === "success" || transaction["status"] === "completed") {
			status = "success";
		} else if(transaction["status"] === "reversed") {
			status = "failed";
		}
		return status;
	};

	const amount = formatCurrency(parseFloat(transaction.amount.toString()));
	let msisdn = transaction.msisdn.replace("+", "");
	if(!msisdn.includes("*")) {
		msisdn = msisdn.substring(0, 5) + "****" + msisdn.substring(msisdn.length - 4);
	}

	const style = {};
	let paddingTop = 50;
	if(Util.device === 'ios') {
		style.top = "64px";
		paddingTop += 69;
	}

	paddingTop = paddingTop+"px";
	return (<MainApp style={{ padding: "0", paddingTop: "0" }}>
		<div className="back-navigator-button" style={style} onClick={() => navigate(-1)}>
			<img src={'/assets/images/arrow-back.png'} alt={'error'}/>
		</div>
		<div className="sliver-app-bar" style={{ paddingTop }}>
			<img src={`/assets/images/invoice-${invoiceStatus}.png`} alt={'error'}/>
			<div className="title">
				<sup><span>{transaction.currency.toUpperCase().trim()}</span></sup>
				{amount.split(".")[0]}
				<sup><span>.{amount.toString().split(".")[1]}</span></sup>
			</div>
			<div className="description" style={{ textTransform: "capitalize" }}>
				{transaction['merchantName'] ? "Payment - "+transaction['merchantName'] : transaction.description}
			</div>
		</div>
		<div className="transaction-details">
			<div className="row" style={{ float: "right", width: "25%", minWidth: "84px" }}>
				<div className="label">Status</div>
				<div style={{ textTransform: "capitalize" }}>{ transaction.status }</div>
			</div>
			<div className="row">
				<div className="label">Issued on:</div>
				<div>{ getFormattedDate(transaction.updatedAt) }</div>
			</div>
			<div className="row">
				<div className="label">Bill from</div>
				<div>{ transaction['merchantName'] }</div>
			</div>
			<div className="row">
				<div className="label">Billed to</div>
				<div>{ `+${msisdn.substring(0, 3)} ${msisdn.substring(3)}` }</div>
			</div>
			<div className="row">
				<div className="label">Description</div>
				<div>{ transaction.description || "--" }</div>
			</div>
		</div>
		<div className="footer-message">{error}</div>
	</MainApp>);
};

export default TransactionDetails;
