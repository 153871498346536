export const countries = [
	// {"name": "Afghanistan", "dialCode": "+93", "countryCode": "AF", "iso": "AFG", "currency": "AFN"},
	// {"name": "Albania", "dialCode": "+355", "countryCode": "AL", "iso": "ALB", "currency": "ALL"},
	// {"name": "Algeria", "dialCode": "+213", "countryCode": "DZ", "iso": "DZA", "currency": "DZD"},
	{"name": "American Samoa", "dialCode": "+1", "countryCode": "AS", "iso": "ASM", "currency": "USD"},
	// {"name": "Andorra", "dialCode": "+376", "countryCode": "AD", "iso": "AND", "currency": "EUR"},
	// {"name": "Angola", "dialCode": "+244", "countryCode": "AO", "iso": "AGO", "currency": "AOA"},
	{"name": "Anguilla", "dialCode": "+1", "countryCode": "AI", "iso": "AIA", "currency": "XCD"},
	{"name": "Antigua and Barbuda", "dialCode": "+1", "countryCode": "AG", "iso": "ATG", "currency": "XCD"},
	{"name": "Argentina", "dialCode": "+54", "countryCode": "AR", "iso": "ARG", "currency": "ARS"},
	// {"name": "Armenia", "dialCode": "+374", "countryCode": "AM", "iso": "ARM", "currency": "AMD"},
	{"name": "Aruba", "dialCode": "+297", "countryCode": "AW", "iso": "ABW", "currency": "AWG"},
	{"name": "Australia", "dialCode": "+61", "countryCode": "AU", "iso": "AUS", "currency": "AUD"},
	// {"name": "Austria", "dialCode": "+43", "countryCode": "AT", "iso": "AUT", "currency": "EUR"},
	// {"name": "Azerbaijan", "dialCode": "+994", "countryCode": "AZ", "iso": "AZE", "currency": "AZN"},
	{"name": "Bahamas", "dialCode": "+1", "countryCode": "BS", "iso": "BHS", "currency": "BSD"},
	{"name": "Bahrain", "dialCode": "+973", "countryCode": "BH", "iso": "BHR", "currency": "BHD"},
	// {"name": "Bangladesh", "dialCode": "+880", "countryCode": "BD", "iso": "BGD", "currency": "BDT"},
	{"name": "Barbados", "dialCode": "+1", "countryCode": "BB", "iso": "BRB", "currency": "BBD"},
	// {"name": "Belarus", "dialCode": "+375", "countryCode": "BY", "iso": "BLR", "currency": "BYN"},
	// {"name": "Belgium", "dialCode": "+32", "countryCode": "BE", "iso": "BEL", "currency": "EUR"},
	{"name": "Belize", "dialCode": "+501", "countryCode": "BZ", "iso": "BLZ", "currency": "BZD"},
	{"name": "Benin", "dialCode": "+229", "countryCode": "BJ", "iso": "BEN", "currency": "XOF"},
	{"name": "Bermuda", "dialCode": "+1", "countryCode": "BM", "iso": "BMU", "currency": "BMD"},
	// {"name": "Bhutan", "dialCode": "+975", "countryCode": "BT", "iso": "BTN", "currency": "BTN"},
	// {"name": "Bolivia", "dialCode": "+591", "countryCode": "BO", "iso": "BOL", "currency": "BOB"},
	{"name": "Bonaire, Sint Eustatius and Saba", "dialCode": "+599", "countryCode": "BQ", "iso": "BES", "currency": "USD"},
	// {"name": "Bosnia and Herzegovina", "dialCode": "+387", "countryCode": "BA", "iso": "BIH", "currency": "BAM"},
	{"name": "Botswana", "dialCode": "+267", "countryCode": "BW", "iso": "BWA", "currency": "BWP"},
	{"name": "Brazil", "dialCode": "+55", "countryCode": "BR", "iso": "BRA", "currency": "BRL"},
	{"name": "British Indian Ocean Territory", "dialCode": "+246", "countryCode": "IO", "iso": "IOT", "currency": "GBP"},
	{"name": "British Virgin Islands", "dialCode": "+1", "countryCode": "VG", "iso": "VGB", "currency": "USD"},
	{"name": "Brunei Darussalam", "dialCode": "+673", "countryCode": "BN", "iso": "BRN", "currency": "BND"},
	// {"name": "Bulgaria", "dialCode": "+359", "countryCode": "BG", "iso": "BGR", "currency": "BGN"},
	// {"name": "Burkina Faso", "dialCode": "+226", "countryCode": "BF", "iso": "BFA", "currency": "XOF"},
	{"name": "Myanmar", "dialCode": "+95", "countryCode": "MM", "iso": "MMR", "currency": "MMK"},
	{"name": "Burundi", "dialCode": "+257", "countryCode": "BI", "iso": "BDI", "currency": "BIF"},
	// {"name": "Cambodia", "dialCode": "+855", "countryCode": "KH", "iso": "KHM", "currency": "KHR"},
	{"name": "Cameroon", "dialCode": "+237", "countryCode": "CM", "iso": "CMR", "currency": "XAF"},
	{"name": "Canada", "dialCode": "+1", "countryCode": "CA", "iso": "CAN", "currency": "CAD"},
	// {"name": "Cape Verde", "dialCode": "+238", "countryCode": "CV", "iso": "CPV", "currency": "CVE"},
	{"name": "Cayman Islands", "dialCode": "+1", "countryCode": "KY", "iso": "CYM", "currency": "KYD"},
	// {"name": "Central African Republic", "dialCode": "+236", "countryCode": "CF", "iso": "CAF", "currency": "XAF"},
	// {"name": "Chad", "dialCode": "+235", "countryCode": "ID", "iso": "TCD", "currency": "XAF"},
	{"name": "Chile", "dialCode": "+56", "countryCode": "CL", "iso": "CHL", "currency": "CLP"},
	// {"name": "China", "dialCode": "+86", "countryCode": "CN", "iso": "CHN", "currency": "CNY"},
	// {"name": "Colombia", "dialCode": "+57", "countryCode": "CO", "iso": "COL", "currency": "COP"},
	// {"name": "Comoros", "dialCode": "+269", "countryCode": "KM", "iso": "COM", "currency": "KMF"},
	{"name": "Cook Islands", "dialCode": "+682", "countryCode": "CK", "iso": "COK", "currency": "NZD"},
	{"name": "Costa Rica", "dialCode": "+506", "countryCode": "CR", "iso": "CRI", "currency": "CRC"},
	// {"name": "Côte dIvoire", "dialCode": "+225", "countryCode": "CI", "iso": "CIV", "currency": "XOF"},
	// {"name": "Croatia", "dialCode": "+385", "countryCode": "HR", "iso": "HRV", "currency": "HRK"},
	// {"name": "Cuba", "dialCode": "+53", "countryCode": "CU", "iso": "CUB", "currency": "CUP"},
	{"name": "Curaçao", "dialCode": "+599", "countryCode": "CW", "iso": "CUW", "currency": "ANG"},
	{"name": "Cyprus", "dialCode": "+357", "countryCode": "CY", "iso": "CYP", "currency": "EUR"},
	// {"name": "Czechia", "dialCode": "+420", "countryCode": "CZ", "iso": "CZE", "currency": "CZK"},
	{"name": "Denmark", "dialCode": "+45", "countryCode": "DK", "iso": "DNK", "currency": "DKK"},
	// {"name": "Djibouti", "dialCode": "+253", "countryCode": "DJ", "iso": "DJI", "currency": "DJF"},
	{"name": "Dominica", "dialCode": "+1", "countryCode": "DM", "iso": "DMA", "currency": "XCD"},
	// {"name": "Dominican Republic", "dialCode": "+1", "countryCode": "DO", "iso": "DOM", "currency": "DOP"},
	// {"name": "Ecuador", "dialCode": "+593", "countryCode": "EC", "iso": "ECU", "currency": "USD"},
	{"name": "Egypt", "dialCode": "+20", "countryCode": "EG", "iso": "EGY", "currency": "EGP"},
	// {"name": "El Salvador", "dialCode": "+503", "countryCode": "SV", "iso": "SLV", "currency": "BTC"},
	// {"name": "Equatorial Guinea", "dialCode": "+240", "countryCode": "GQ", "iso": "GNQ", "currency": "XAF"},
	{"name": "Eritrea", "dialCode": "+291", "countryCode": "ER", "iso": "ERI", "currency": "ERN"},
	// {"name": "Estonia", "dialCode": "+372", "countryCode": "EE", "iso": "EST", "currency": "EUR"},
	{"name": "Ethiopia", "dialCode": "+251", "countryCode": "ET", "iso": "ETH", "currency": "ETB"},
	{"name": "Falkland Islands", "dialCode": "+500", "countryCode": "FK", "iso": "FLK", "currency": "FKP"},
	// {"name": "Faroe Islands", "dialCode": "+298", "countryCode": "FO", "iso": "FRO", "currency": "DKK"},
	{"name": "Federated States of Micronesia", "dialCode": "+691", "countryCode": "FM", "iso": "FSM", "currency": "USD"},
	{"name": "Fiji", "dialCode": "+679", "countryCode": "FJ", "iso": "FJI", "currency": "FJD"},
	// {"name": "Finland", "dialCode": "+358", "countryCode": "FI", "iso": "FIN", "currency": "EUR"},
	{"name": "France", "dialCode": "+33", "countryCode": "FR", "iso": "FRA", "currency": "EUR"},
	{"name": "French Guiana", "dialCode": "+594", "countryCode": "GF", "iso": "GUF", "currency": "EUR"},
	// {"name": "French Polynesia", "dialCode": "+689", "countryCode": "PF", "iso": "PYF", "currency": "EUR"},
	// {"name": "Gabon", "dialCode": "+241", "countryCode": "GA", "iso": "GAB", "currency": "XAF"},
	{"name": "Gambia", "dialCode": "+220", "countryCode": "GM", "iso": "GMB", "currency": "GMD"},
	// {"name": "Georgia", "dialCode": "+995", "countryCode": "GE", "iso": "GEO", "currency": "GEL"},
	// {"name": "Germany", "dialCode": "+49", "countryCode": "DE", "iso": "DEU", "currency": "EUR"},
	{"name": "Ghana", "dialCode": "+233", "countryCode": "GH", "iso": "GHA", "currency": "GHS"},
	{"name": "Gibraltar", "dialCode": "+350", "countryCode": "GI", "iso": "GIB", "currency": "GIP"},
	{"name": "Greece", "dialCode": "+30", "countryCode": "GR", "iso": "GRC", "currency": "EUR"},
	{"name": "Greenland", "dialCode": "+299", "countryCode": "GL", "iso": "GRL", "currency": "DKK"},
	{"name": "Grenada", "dialCode": "+1", "countryCode": "GD", "iso": "GRD", "currency": "XCD"},
	{"name": "Guadeloupe", "dialCode": "+590", "countryCode": "GP", "iso": "GLP", "currency": "EUR"},
	{"name": "Guam", "dialCode": "+1", "countryCode": "GU", "iso": "GUM", "currency": "USD"},
	// {"name": "Guatemala", "dialCode": "+502", "countryCode": "GT", "iso": "GTM", "currency": "GTQ"},
	// {"name": "Guinea", "dialCode": "+224", "countryCode": "GN", "iso": "GIN", "currency": "GNF"},
	// {"name": "Guinea-Bissau", "dialCode": "+245", "countryCode": "GW", "iso": "GNB", "currency": "XOF"},
	{"name": "Guyana", "dialCode": "+592", "countryCode": "GY", "iso": "GUY", "currency": "GYD"},
	// {"name": "Haiti", "dialCode": "+509", "countryCode": "HT", "iso": "HTI", "currency": "HTG"},
	// {"name": "Honduras", "dialCode": "+504", "countryCode": "HN", "iso": "HND", "currency": "HNL"},
	{"name": "Hong Kong", "dialCode": "+852", "countryCode": "HK", "iso": "HKG", "currency": "HKD"},
	{"name": "Hungary", "dialCode": "+36", "countryCode": "HU", "iso": "HUN", "currency": "HUF"},
	{"name": "Iceland", "dialCode": "+354", "countryCode": "IS", "iso": "ISL", "currency": "ISK"},
	{"name": "India", "dialCode": "+91", "countryCode": "IN", "iso": "IND", "currency": "INR"},
	{"name": "Indonesia", "dialCode": "+62", "countryCode": "ID", "iso": "IDN", "currency": "IDR"},
	// {"name": "Iran", "dialCode": "+98", "countryCode": "IR", "iso": "IRN", "currency": "IRR"},
	// {"name": "Iraq", "dialCode": "+964", "countryCode": "IQ", "iso": "IRQ", "currency": "IQD"},
	{"name": "Ireland", "dialCode": "+353", "countryCode": "IE", "iso": "IRL", "currency": "GBP"},
	{"name": "Israel", "dialCode": "+972", "countryCode": "IL", "iso": "ISR", "currency": "ILS"},
	// {"name": "Italy", "dialCode": "+39", "countryCode": "IT", "iso": "ITA", "currency": "EUR"},
	{"name": "Jamaica", "dialCode": "+1", "countryCode": "JM", "iso": "JAM", "currency": "JMD"},
	// {"name": "Japan", "dialCode": "+81", "countryCode": "JP", "iso": "JPN", "currency": "JPY"},
	{"name": "Jordan", "dialCode": "+962", "countryCode": "JO", "iso": "JOR", "currency": "JOD"},
	// {"name": "Kazakhstan", "dialCode": "+7", "countryCode": "KZ", "iso": "KAZ", "currency": "KZT"},
	{"name": "Kenya", "dialCode": "+254", "countryCode": "KE", "iso": "KEN", "currency": "KES"},
	{"name": "Kiribati", "dialCode": "+686", "countryCode": "KI", "iso": "KIR", "currency": "AUD"},
	{"name": "Kuwait", "dialCode": "+965", "countryCode": "KW", "iso": "KWT", "currency": "KWD"},
	// {"name": "Kyrgyzstan", "dialCode": "+996", "countryCode": "KG", "iso": "KGZ", "currency": "KGS"},
	{"name": "Laos", "dialCode": "+856", "countryCode": "LA", "iso": "LAO", "currency": "LAK"},
	{"name": "Latvia", "dialCode": "+371", "countryCode": "LV", "iso": "LVA", "currency": "EUR"},
	{"name": "Lebanon", "dialCode": "+961", "countryCode": "LB", "iso": "LBN", "currency": "LBP"},
	{"name": "Lesotho", "dialCode": "+266", "countryCode": "LS", "iso": "LSO", "currency": "ZAR"},
	// {"name": "Liberia", "dialCode": "+231", "countryCode": "LR", "iso": "LBR", "currency": "LRD"},
	{"name": "Libya", "dialCode": "+218", "countryCode": "LY", "iso": "LBY", "currency": "LYD"},
	// {"name": "Liechtenstein", "dialCode": "+423", "countryCode": "LI", "iso": "LIE", "currency": "CHF"},
	// {"name": "Lithuania", "dialCode": "+370", "countryCode": "LT", "iso": "LTU", "currency": "EUR"},
	// {"name": "Luxembourg", "dialCode": "+352", "countryCode": "LU", "iso": "LUX", "currency": "EUR"},
	{"name": "Macao", "dialCode": "+853", "countryCode": "MO", "iso": "MAC", "currency": "MOP"},
	// {"name": "Macedonia", "dialCode": "+389", "countryCode": "MK", "iso": "MKD", "currency": "MKD"},
	{"name": "Madagascar", "dialCode": "+261", "countryCode": "MG", "iso": "MDG", "currency": "MGA"},
	{"name": "Malawi", "dialCode": "+265", "countryCode": "MW", "iso": "MWI", "currency": "MWK"},
	{"name": "Malaysia", "dialCode": "+60", "countryCode": "MY", "iso": "MYS", "currency": "MYR"},
	{"name": "Maldives", "dialCode": "+960", "countryCode": "MV", "iso": "MDV", "currency": "MVR"},
	// {"name": "Mali", "dialCode": "+223", "countryCode": "ML", "iso": "MLI", "currency": "XOF"},
	{"name": "Malta", "dialCode": "+356", "countryCode": "MT", "iso": "MLT", "currency": "EUR"},
	{"name": "Marshall Islands", "dialCode": "+692", "countryCode": "MH", "iso": "MHL", "currency": "USD"},
	{"name": "Martinique", "dialCode": "+596", "countryCode": "MQ", "iso": "MTQ", "currency": "EUR"},
	// {"name": "Mauritania", "dialCode": "+222", "countryCode": "MR", "iso": "MRT", "currency": "MRU"},
	{"name": "Mauritius", "dialCode": "+230", "countryCode": "MU", "iso": "MUS", "currency": "MUR"},
	{"name": "Mayotte", "dialCode": "+262", "countryCode": "YT", "iso": "MYT", "currency": "EUR"},
	// {"name": "Mexico", "dialCode": "+52", "countryCode": "MX", "iso": "MEX", "currency": "MXN"},
	// {"name": "Moldova", "dialCode": "+373", "countryCode": "MD", "iso": "MDA", "currency": "MDL"},
	{"name": "Monaco", "dialCode": "+377", "countryCode": "MC", "iso": "MCO", "currency": "EUR"},
	// {"name": "Mongolia", "dialCode": "+976", "countryCode": "MN", "iso": "MNG", "currency": "MNT"},
	// {"name": "Montenegro", "dialCode": "+382", "countryCode": "ME", "iso": "MNE", "currency": "EUR"},
	{"name": "Montserrat", "dialCode": "+1", "countryCode": "MS", "iso": "MSR", "currency": "XCD"},
	// {"name": "Morocco", "dialCode": "+212", "countryCode": "MA", "iso": "MAR", "currency": "MAD"},
	// {"name": "Mozambique", "dialCode": "+258", "countryCode": "MZ", "iso": "MOZ", "currency": "MZN"},
	{"name": "Namibia", "dialCode": "+264", "countryCode": "NA", "iso": "NAM", "currency": "NAD"},
	{"name": "Nauru", "dialCode": "+674", "countryCode": "NR", "iso": "NRU", "currency": "AUD"},
	{"name": "Nepal", "dialCode": "+977", "countryCode": "NP", "iso": "NPL", "currency": "NPR"},
	{"name": "Netherlands", "dialCode": "+31", "countryCode": "NL", "iso": "NLD", "currency": "EUR"},
	{"name": "Netherlands Antilles", "dialCode": "+599", "countryCode": "AN", "iso": "ANT", "currency": "ANG"},
	// {"name": "New Caledonia", "dialCode": "+687", "countryCode": "NC", "iso": "NCL", "currency": "EUR"},
	{"name": "New Zealand", "dialCode": "+64", "countryCode": "NZ", "iso": "NZL", "currency": "NZD"},
	{"name": "Nicaragua", "dialCode": "+505", "countryCode": "NI", "iso": "NIC", "currency": "NIO"},
	// {"name": "Niger", "dialCode": "+227", "countryCode": "NE", "iso": "NER", "currency": "XOF"},
	{"name": "Nigeria", "dialCode": "+234", "countryCode": "NG", "iso": "NGA", "currency": "NGN"},
	{"name": "Niue", "dialCode": "+683", "countryCode": "NU", "iso": "NIU", "currency": "NZD"},
	{"name": "Norfolk Island", "dialCode": "+672", "countryCode": "NF", "iso": "NFK", "currency": "AUD"},
	// {"name": "North Korea", "dialCode": "+850", "countryCode": "KP", "iso": "PRK", "currency": "KPW"},
	{"name": "Northern Mariana Islands", "dialCode": "+1", "countryCode": "MP", "iso": "MNP", "currency": "USD"},
	// {"name": "Norway", "dialCode": "+47", "countryCode": "NO", "iso": "NOR", "currency": "NOK"},
	{"name": "Oman", "dialCode": "+968", "countryCode": "OM", "iso": "OMN", "currency": "OMR"},
	{"name": "Pakistan", "dialCode": "+92", "countryCode": "PK", "iso": "PAK", "currency": "PKR"},
	{"name": "Palau", "dialCode": "+680", "countryCode": "PW", "iso": "PLW", "currency": "USD"},
	{"name": "Palestine", "dialCode": "+970", "countryCode": "PS", "iso": "PSE", "currency": "EGP"},
	{"name": "Panama", "dialCode": "+507", "countryCode": "PA", "iso": "PAN", "currency": "PAB"},
	{"name": "Papua New Guinea", "dialCode": "+675", "countryCode": "PG", "iso": "PNG", "currency": "PGK"},
	// {"name": "Paraguay", "dialCode": "+595", "countryCode": "PY", "iso": "PRY", "currency": "PYG"},
	{"name": "Peru", "dialCode": "+51", "countryCode": "PE", "iso": "PER", "currency": "PEN"},
	{"name": "Philippines", "dialCode": "+63", "countryCode": "PH", "iso": "PHL", "currency": "PHP"},
	// {"name": "Poland", "dialCode": "+48", "countryCode": "PL", "iso": "POL", "currency": "PLN"},
	// {"name": "Portugal", "dialCode": "+351", "countryCode": "PT", "iso": "PRT", "currency": "EUR"},
	{"name": "Puerto Rico", "dialCode": "+1", "countryCode": "PR", "iso": "PRI", "currency": "USD"},
	{"name": "Qatar", "dialCode": "+974", "countryCode": "QA", "iso": "QAT", "currency": "QAR"},
	// {"name": "Congo", "dialCode": "+242", "countryCode": "CG", "iso": "COG", "currency": "XAF"},
	{"name": "Réunion", "dialCode": "+262", "countryCode": "RE", "iso": "REU", "currency": "EUR"},
	// {"name": "Romania", "dialCode": "+40", "countryCode": "RO", "iso": "ROU", "currency": "RON"},
	// {"name": "Russia", "dialCode": "+7", "countryCode": "RU", "iso": "RUS", "currency": "RUB"},
	{"name": "Rwanda", "dialCode": "+250", "countryCode": "RW", "iso": "RWA", "currency": "RWF"},
	{"name": "Saint Barthélemy", "dialCode": "+590", "countryCode": "BL", "iso": "BLM", "currency": "EUR"},
	{"name": "Saint Helena", "dialCode": "+290", "countryCode": "SH", "iso": "SHN", "currency": "SHP"},
	{"name": "Saint Kitts and Nevis", "dialCode": "+1", "countryCode": "KN", "iso": "KNA", "currency": "XCD"},
	{"name": "Saint Martin", "dialCode": "+590", "countryCode": "MF", "iso": "MAF", "currency": "EUR"},
	// {"name": "Saint Pierre and Miquelon", "dialCode": "+508", "countryCode": "PM", "iso": "SPM", "currency": "EUR"},
	{"name": "Saint Vincent and the Grenadines", "dialCode": "+1", "countryCode": "VC", "iso": "VCT", "currency": "XCD"},
	{"name": "Samoa", "dialCode": "+685", "countryCode": "WS", "iso": "WSM", "currency": "WST"},
	// {"name": "San Marino", "dialCode": "+378", "countryCode": "SM", "iso": "SMR", "currency": "EUR"},
	{"name": "São Tomé and Príncipe", "dialCode": "+239", "countryCode": "ST", "iso": "STP", "currency": "STN"},
	// {"name": "Saudi Arabia", "dialCode": "+966", "countryCode": "SA", "iso": "SAU", "currency": "SAR"},
	{"name": "Senegal", "dialCode": "+221", "countryCode": "SN", "iso": "SEN", "currency": "XOF"},
	// {"name": "Serbia", "dialCode": "+381", "countryCode": "RS", "iso": "SRB", "currency": "RSD"},
	{"name": "Seychelles", "dialCode": "+248", "countryCode": "SC", "iso": "SYC", "currency": "SCR"},
	{"name": "Sierra Leone", "dialCode": "+232", "countryCode": "SL", "iso": "SLE", "currency": "SLL"},
	{"name": "Singapore", "dialCode": "+65", "countryCode": "SG", "iso": "SGP", "currency": "SGD"},
	{"name": "Sint Maarten (Dutch part)", "dialCode": "+1", "countryCode": "SX", "iso": "SXM", "currency": "ANG"},
	// {"name": "Slovakia", "dialCode": "+421", "countryCode": "SK", "iso": "SVK", "currency": "EUR"},
	// {"name": "Slovenia", "dialCode": "+386", "countryCode": "SI", "iso": "SVN", "currency": "EUR"},
	{"name": "Solomon Islands", "dialCode": "+677", "countryCode": "SB", "iso": "SLB", "currency": "SBD"},
	{"name": "Somalia", "dialCode": "+252", "countryCode": "SO", "iso": "SOM", "currency": "SOS"},
	{"name": "South Africa", "dialCode": "+27", "countryCode": "ZA", "iso": "ZAF", "currency": "ZAR"},
	{"name": "South Korea", "dialCode": "+82", "countryCode": "KR", "iso": "KOR", "currency": "KRW"},
	{"name": "South Sudan", "dialCode": "+211", "countryCode": "SS", "iso": "SSD", "currency": "SSP"},
	// {"name": "Spain", "dialCode": "+34", "countryCode": "ES", "iso": "ESP", "currency": "EUR"},
	{"name": "Sri Lanka", "dialCode": "+94", "countryCode": "LK", "iso": "LKA", "currency": "LKR"},
	{"name": "Saint Lucia", "dialCode": "+1", "countryCode": "LC", "iso": "LCA", "currency": "XCD"},
	// {"name": "Sudan", "dialCode": "+249", "countryCode": "SD", "iso": "SDN", "currency": "SDG"},
	{"name": "Suriname", "dialCode": "+597", "countryCode": "SR", "iso": "SUR", "currency": "SRD"},
	{"name": "Swaziland", "dialCode": "+268", "countryCode": "SZ", "iso": "SWZ", "currency": "SZL"},
	// {"name": "Sweden", "dialCode": "+46", "countryCode": "SE", "iso": "SWE", "currency": "SEK"},
	{"name": "Switzerland", "dialCode": "+41", "countryCode": "CH", "iso": "CHE", "currency": "CHF"},
	// {"name": "Syrian Arab Republic", "dialCode": "+963", "countryCode": "SY", "iso": "SYR", "currency": "SYP"},
	// {"name": "Taiwan", "dialCode": "+886", "countryCode": "TW", "iso": "TWN", "currency": "TWD"},
	// {"name": "Tajikistan", "dialCode": "+992", "countryCode": "TJ", "iso": "TJK", "currency": "TJS"},
	{"name": "Tanzania", "dialCode": "+255", "countryCode": "TZ", "iso": "TZA", "currency": "TZS"},
	{"name": "Thailand", "dialCode": "+66", "countryCode": "TH", "iso": "THA", "currency": "THB"},
	{"name": "Timor-Leste", "dialCode": "+670", "countryCode": "TL", "iso": "TLS", "currency": "USD"},
	// {"name": "Togo", "dialCode": "+228", "countryCode": "TG", "iso": "TGO", "currency": "XOF"},
	{"name": "Tokelau", "dialCode": "+690", "countryCode": "TK", "iso": "TKL", "currency": "NZD"},
	{"name": "Tonga", "dialCode": "+676", "countryCode": "TO", "iso": "TON", "currency": "TOP"},
	{"name": "Trinidad and Tobago", "dialCode": "+1", "countryCode": "TT", "iso": "TTO", "currency": "TTD"},
	// {"name": "Tunisia", "dialCode": "+216", "countryCode": "TN", "iso": "TUN", "currency": "TND"},
	// {"name": "Turkey", "dialCode": "+90", "countryCode": "TR", "iso": "TUR", "currency": "TRY"},
	// {"name": "Turkmenistan", "dialCode": "+993", "countryCode": "TM", "iso": "TKM", "currency": "TMT"},
	{"name": "Turks and Caicos Islands", "dialCode": "+1", "countryCode": "TC", "iso": "TCA", "currency": "USD"},
	{"name": "Tuvalu", "dialCode": "+688", "countryCode": "TV", "iso": "TUV", "currency": "AUD"},
	{"name": "Uganda", "dialCode": "+256", "countryCode": "UG", "iso": "UGA", "currency": "UGX"},
	// {"name": "Ukraine", "dialCode": "+380", "countryCode": "UA", "iso": "UKR", "currency": "UAH"},
	{"name": "United Arab Emirates", "dialCode": "+971", "countryCode": "AE", "iso": "ARE", "currency": "AED"},
	{"name": "United Kingdom", "dialCode": "+44", "countryCode": "GB", "iso": "GBR", "currency": "GBP"},
	{"name": "United States", "dialCode": "+1", "countryCode": "US", "iso": "USA", "currency": "USD"},
	// {"name": "Uruguay", "dialCode": "+598", "countryCode": "UY", "iso": "URY", "currency": "UYU"},
	{"name": "U.S. Virgin Islands", "dialCode": "+1", "countryCode": "VI", "iso": "VIR", "currency": "USD"},
	// {"name": "Uzbekistan", "dialCode": "+998", "countryCode": "UZ", "iso": "UZB", "currency": "UZS"},
	{"name": "Vanuatu", "dialCode": "+678", "countryCode": "VU", "iso": "VUT", "currency": "VUV"},
	{"name": "Vatican City", "dialCode": "+39", "countryCode": "VA", "iso": "VAT", "currency": "EUR"},
	// {"name": "Venezuela", "dialCode": "+58", "countryCode": "VE", "iso": "VEN", "currency": "VED"},
	{"name": "Vietnam", "dialCode": "+84", "countryCode": "VN", "iso": "VNM", "currency": "VND"},
	// {"name": "Wallis and Futuna", "dialCode": "+681", "countryCode": "WF", "iso": "WLF", "currency": "XPF"},
	// {"name": "Yemen", "dialCode": "+967", "countryCode": "YE", "iso": "YEM", "currency": "YER"},
	{"name": "Zambia", "dialCode": "+260", "countryCode": "ZM", "iso": "ZMB", "currency": "ZMW"},
	// {"name": "Zimbabwe", "dialCode": "+263", "countryCode": "ZW", "iso": "ZWE", "currency": "ZWL"}
];

export const getCountryByCurrency = (currency) => {
	return countries.find(country => country.currency.toUpperCase().trim() === currency.toUpperCase().trim());
};

export const getCountryByCode = (countryCode) => {
	return countries.find(country => country.countryCode.toUpperCase().trim() === countryCode.toUpperCase().trim());
};

export const getCountryByISO = (iso) => {
	return countries.find(country => country.iso.toUpperCase().trim() === iso.toUpperCase().trim());
};
