import React, { useEffect, useState} from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

// import Home from './pages/Home';
import NotFound from './pages/NotFound';
import NewTransaction from './pages/NewTransaction';
import TransactionStatus from './pages/TransactionStatus';
import PhoneNumberLogin from "./pages/PhoneNumberLogin";
import Transactions from "./pages/Transactions";
import TransactionDetails from "./pages/TransactionDetails";

import * as Util from "./utils/util";
import * as Theme from "./utils/theme";

import './styles/dark-theme.css';

function App() {
	const [theme, setTheme] = useState('');

	useEffect(() => {
		const onMessageHandler = (event) => {
			if(!event || !event.data) return;

			const message = event.data;
			if(message['setDarkTheme'] !== undefined) {
				setAppTheme(message['setDarkTheme'] ? 'dark' : 'light');
			}
		};

		window.addEventListener("message", onMessageHandler);
		setTimeout(() => Util.showAppWidget(), 50);
		return () => {
			window.removeEventListener("message", onMessageHandler);
		};
	}, []);

	useEffect(() => {
		setAppTheme(Theme.prefersDarkScheme.matches ? 'dark' : 'light');

		const updateTheme = (e) => {
			setAppTheme(e.matches ? 'dark' : 'light');
		};

		const updateVisibleHeight = () => {
			const visibleHeight = window.innerHeight;
			document.documentElement.style.setProperty('--visible-height', `${visibleHeight}px`);
		};

		Theme.prefersDarkScheme.addEventListener('change', updateTheme);
		window.addEventListener('resize', updateVisibleHeight);
		updateVisibleHeight();

		return () => {
			Theme.prefersDarkScheme.removeEventListener('change', updateTheme);
			window.removeEventListener('resize', updateVisibleHeight);
		};
	}, [theme]);

	const setAppTheme = (appTheme) => {
		document.body.style.background = appTheme === 'dark' ? "#1B1D1F" : "#FFFFFF";
		document.body.style.color = appTheme === 'dark' ? "#FFFFFF": "#000000";
		setTheme(appTheme);
	};

	return (
		<div className={`${Util.device} ${theme}`}>
			{ Util.device === 'android' && <div className="backdrop blur"/> }
			<Router>
				<Routes>
					{/*<Route exact path="/" element={<Home />} />*/}
					<Route exact path="/" element={<PhoneNumberLogin />} />
					<Route exact path="/auth" element={<PhoneNumberLogin />} />
					<Route exact path="/transactions" element={<Transactions />} />
					<Route exact path="/transactions/:transactionRef" element={<TransactionDetails />} />

					<Route path="/payment/:transactionRef" element={<NewTransaction />} />
					<Route path="/payment/:transactionRef/status" element={<TransactionStatus />} />
					<Route component={NotFound} />
				</Routes>
			</Router>
		</div>
	);
}

export default App;
