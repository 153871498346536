import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import * as Util from "../../utils/util";
import { BASE_URL, handleFocus, handleBlur } from "../../utils/util";
import IOSButton from "./IOSButton";
import Loading from "./Loading";
import axios from "axios";
import InputField from "./InputField";
import { encrypt } from "../../utils/crypto";

const Pin = ({ transaction, onValidated, requestPath, isCVV=false }) => {
	const pinLength = ((transaction || {}).method || {}).pinLength || (isCVV ? 3 : 4);
	let { transactionRef } = useParams();
	transactionRef = transactionRef.split(":")[0];
	const [pin, setPin] = useState('');
	const [error, setError] = useState('');
	const [loading, setLoading] = useState(false);
	const [buttonDisabled, setButtonDisabled] = useState(true);
	const navigate = useNavigate();
	const inputRef = useRef();

	useEffect(() => {
		Util.setWebviewContainerHeight(Util.HEIGHT_DEFAULT);
		if(inputRef.current) inputRef.current['focus']();
	}, []);

	const handleChange = (event) => {
		setButtonDisabled(event.target.value.trim().length < pinLength);
		setPin(event.target.value.trim());
		setError('');
	};

	const handleSubmit = async (event) => {
		event.preventDefault();
		if(loading) return;

		if(pin.trim().length < pinLength) {
			setError(`Enter a valid ${isCVV ? 'cvv' : 'pin'}`);
			return;
		}

		try {
			setLoading(true);
			const payload = encrypt({ 'pin': pin.trim() });
			// const method = transaction.method.type === 'payment' ? 'card' : 'bank';
			const response = await axios.post(`${BASE_URL}/api/payment/${transactionRef}${requestPath || '/auth'}`, { payload });
			if (response.data.status === 'completed' || response.data.status === 'successful'
				|| response.data.status === 'failed' || response.data.status === 'reversed'
				|| response.data.status === 'vended' || response.data.status === 'debited') {
				navigate(`/payment/${transactionRef}/status`, { replace: true });
			} else if(onValidated) {
				setPin("");
				onValidated(response.data);
			}
		} catch (error) {
			if(!error.response) {
				console.error('Error processing transaction:', error);
				setError(error.toString());
				return;
			}

			const response = error.response;
			let errorMsg = response.statusText;
			if(response.data && response.data.message) {
				errorMsg = response.data.message;
			} else if(response.data && response.data.error) {
				errorMsg = response.data.error.message || response.data.error.toString();
			} setError(errorMsg);

			if(response.status === 408 || response.status === 504) {
				setTimeout(() => {
					navigate(`/payment/${transactionRef}/status`, { replace: true });
				}, 100);
			} else if(response.status === 403 || response.status === 410) {
				setTimeout(() => {
					navigate(`/payment/${transactionRef}/status`, { replace: true });
				}, 350);
			}
			/*if(error.response && error.response.status === 404) {
				setError('Payment not found');
			} else if(error.response && error.response.status === 401) {
				setError('Pin is incorrect. Try again');
			} else {
				console.error('Error verifying pin:', error);
				setError(error.toString());
			}*/
		} finally {
			setLoading(false);
		}
	};

	const subtitle = (transaction.nextStep || {}).message;
	const defaultSubtitle = 'Enter the PIN you use on your card or bank’s app to authorize this transaction';
	return (
		<div className="upi-widget-container">
			<div className="upi-widget-header">
				<div className="upi-widget-brand-logo">
					<img src={`/assets/images/logos/${transaction.brand.logo || 'default.png'}`} alt="logo"/>
				</div>
				<div className="upi-widget-brand-name">
					<h1>{transaction.brand.name || 'Pay by Vendy'}</h1>
				</div>
			</div>
			<div style={{ clear: 'both' }}/>
			<hr style={{ marginTop: '12px' }}/>
			<form onSubmit={handleSubmit}>
				<div className="upi-widget-title">{isCVV ? 'Verify card details' : 'Enter your PIN'}</div>
				<div className="upi-widget-subtitle">
					{subtitle ? subtitle : isCVV ? "Enter your preferred payment card's CVV number to continue" : defaultSubtitle}
				</div>
				<InputField
					id="pin"
					name="pin"
					value={pin}
					type="password"
					autoFocus={true}
					inputMode="numeric"
					inputRef={inputRef}
					onChange={handleChange}
					onFocus={handleFocus}
					onBlur={handleBlur}
					label={isCVV ? "CVV Number" : "Pin Code"}
					error={error}
				/>
				{error && <div className="upi-widget-text-error">
					<img src={'/assets/images/error-small.png'} alt={'error'}/> {error}
				</div>}
				<div className="upi-widget-footer">
					<div className="left"><button type="button" onClick={Util.closeApp}>Cancel</button></div>
					<div className="vertical-line"/>
					<div className="right">
						{Util.device === 'ios' && (<IOSButton type="submit" className={buttonDisabled ? 'disabled' : ''} isLoading={loading}/>)}
						{Util.device !== 'ios' && (<button type="submit">Next</button>)}
					</div>
				</div>
			</form>
			{ loading && Util.device !== 'ios' && <Loading message={'Authorizing...'} /> }
		</div>
	);
};

export default Pin;
