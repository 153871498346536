
const months = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEPT", "OCT", "NOV", "DEC"];

export const formatCurrency = (number) => {
	let formatter = new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'});
	return formatter.format(number).replace("$", "");
};

export const getFormattedDate = (date, compress=false) => {
	if(typeof date === "string") {
		date = Date.parse(date);
	}
	if(isNaN(date)) return null;

	date = new Date(date);
	let formattedDate = "";
	const today = new Date();
	if(!(compress && today.getMonth() === date.getMonth() && today.getDate() === date.getDate() && today.getFullYear() === date.getFullYear())) {
		formattedDate += `${months[date.getMonth()].toLowerCase()} ${date.getDate()}, ${date.getFullYear()}`;
		formattedDate = formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
	}
	if(!compress) formattedDate += " - ";
	if(!compress || (today.getMonth() === date.getMonth() && today.getDate() === date.getDate() && today.getFullYear() === date.getFullYear())) {
		let period = "AM";
		let hours = date.getHours();
		if(hours > 12) {
			hours = hours%12;
			if(hours !== 0) {
				period = "PM"
			}
		}

		if(hours < 10) hours = "0"+hours;
		let minutes = date.getMinutes();
		if(minutes < 10) minutes = "0"+minutes;
		formattedDate += `${hours}:${minutes}${period}`;
	}

	return formattedDate.trim();
};
