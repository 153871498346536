import React from 'react';
import * as Util from "../../utils/util";

const MainApp = ({ children, style={} }) => {
	if(!style.paddingTop && Util.device === 'ios') {
		style.paddingTop = "44px";
	}

	return <div className="main-app" style={style}>
		{children}
	</div>;
};

export default MainApp;
