import forge from 'node-forge';

const clientPublicKey = "-----BEGIN PUBLIC KEY-----\n" +
    "MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEApJsObtfADdA5sf5/Hy1z\n" +
    "COEHdavYVsLfoPjNqE5jLtNwuIErPeAaofZZ/qHFYtIjb74lfTJIwzd7zohtMFDp\n" +
    "8daWXi8P/RLtF6BTvjHEJPTIY6wsegIQ0Ior9sdGmrQYMxWxbjZbnGmfXGCbCLiv\n" +
    "8Z9qIBlre7XA81CWuzbzGxIaHKSO/zbyD6RTCxi74F45eq/dtHARTEBSEFAjSMQi\n" +
    "oaKaZgxYDl7J75/WZhxyIgvy4sTe5Ur/Uc9XSm+MxBaxd/+O16R7jTZqud/qRFid\n" +
    "/GMFnwXSEpXSMItMM5oYKpn4KFKwsHFGkQDljxjHZWAjuzxWNd41z1mW62zLcF9T\n" +
    "wwIDAQAB\n" +
    "-----END PUBLIC KEY-----";

export const encrypt = (data) => {
    if(typeof data === "object") data = JSON.stringify(data);
    const publicKey = forge.pki.publicKeyFromPem(clientPublicKey);
    const encrypted = publicKey.encrypt(data);
    return forge.util.encode64(encrypted);
};
