import React from 'react';
import { formatCurrency, getFormattedDate } from "../../utils/formatters";

const TransactionTile = ({ transaction, onTap }) => {

	let channel = transaction.channel;
	channel = channel.charAt(0).toUpperCase() + channel.slice(1);
	if(channel.toLowerCase().trim() === "ussd") channel = "USSD";
	const status = transaction.status.toLowerCase().trim();
	const failedStatuses = "failed,reversed";
	const amount = formatCurrency(parseFloat(transaction.amount.toString()));

	return (<div className="transaction-tile" onClick={onTap}>
		<div className="trailing">-{transaction.currency}{amount} <img src={'/assets/images/chevron-right.png'} alt={'more'}/></div>
		<div className="leading">
			<div className="title">{channel} Payment</div>
			<div className="subtitle">{getFormattedDate(transaction.updatedAt, true)}
				{(failedStatuses.includes(status) || status !== "successful") && <span style={{ margin: "0 6px" }}>&bull;</span>}
				{failedStatuses.includes(status) || status !== "successful" ? <span style={{
					color: failedStatuses.includes(status) ? "#F24646" : "#8C8C8C",
					textTransform: "capitalize",
					fontWeight: "600"
				}}>{transaction.status}</span> : ""}
			</div>
		</div>
	</div>);
};

export default TransactionTile;
